import axios from "axios"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const AnnounceModal = () => {

    const [duyuru, setDuyuru] = useState(false);
    const [duyuruData, setDuyuruData] = useState('');



    useEffect(() => {

        if (!sessionStorage.getItem('duyuru')) {
            axios
                .get(`${process.env.REACT_APP_DUYURU_API_URL}`, {
                    headers: {
                        Authorization: 'Basic ' + btoa(`${process.env.REACT_APP_DUYURU_USER}:${process.env.REACT_APP_DUYURU_PASS}`),
                    },
                })
                .then((res) => {
                    if (res.data.data !== null && res.data.statusCode === 200) {
                        setDuyuru(true);
                        setDuyuruData(res.data);
                    }
                })
                .catch((err) => {
                    console.log({ err }, 'Duyuru Hatası');
                });
        }
    }, []);

    return (
        <>
            {!sessionStorage.getItem('duyuru') &&
                <div id="default-modal" tabindex="-1" className={`${duyuru ? 'flex' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                    <div className="relative w-full max-w-2xl max-h-full p-4">
                        <div className="relative bg-white rounded-lg shadow-sm dark:bg-gray-700">
                            <div className="flex items-center justify-between p-4 border-b border-gray-200 rounded-t md:p-5 dark:border-gray-600">
                                {/* <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                        Terms of Service
                                    </h3> */}
                                <button onClick={() => {
                                    setDuyuru(false)
                                    sessionStorage.setItem('duyuru', true)
                                    }} type="button" className="inline-flex items-center justify-center w-8 h-8 text-sm text-gray-400 bg-transparent rounded-lg hover:bg-gray-200 hover:text-gray-900 ms-auto dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                    <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <div className="p-4 space-y-4 md:p-5">
                                <Link
                                    className="col-12"
                                    to={{
                                        path: duyuruData?.data?.link,
                                    }}
                                    target={'_blank'}
                                    onClick={(e) => {
                                        if (duyuruData?.data?.link === null) {
                                            e.preventDefault();
                                        }
                                    }}>
                                    <div className="flex items-center mb-4 col-12">
                                        <img src={duyuruData?.data?.imageUrl} alt={'Merkezi Duyuru Sistemi'} className="mx-auto" />
                                    </div>
                                    <p className="fs-6 text-dark-liver fw-medium">{duyuruData?.data?.content}</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>

    )
}

export default AnnounceModal